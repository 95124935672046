import React, { useEffect, useState } from "react";
import { Typography, Grid, Container } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  episodeReel?: any;
}

export default function LinkedinReels({ episodeReel }: Props) {
  const [t, i18n] = useTranslation("global");

  return (
    <Grid container justifyContent="center">
      <Grid item md={12} xs={12} pb={4} pr={{ xl: 4, md: 2, xs: 0 }}>
        <Typography
          variant="h2"
          mt={2}
          textAlign="center"
          sx={{
            color: "var(--primary-80)",
          }}
        >
          {t("taroko-talks.reel-title")}
        </Typography>
      </Grid>
      <Grid item md={7} xs={12} pr={{ xl: 4, md: 2, xs: 0 }}>
        <iframe
          src={episodeReel?.url}
          height="600"
          width="100%"
          frameBorder={0}
          title="Publicación integrada"
          allowFullScreen
        ></iframe>
      </Grid>
    </Grid>
  );
}
