import React from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { Typography, Grid, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { collection, addDoc } from "firebase/firestore";
import { db, functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import "./Banner.css";

var moment = require("moment");

function Banner() {
  const [t, i18n] = useTranslation("global");
  const lng = i18n.resolvedLanguage;

  const cardImage =
    lng === "es" ? "./img/cards-hero.png" : "./img/en/cards-hero.png";

  const navigate = useNavigate();
  var date = moment().format();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    const info = {
      datetime: date,
      email: data.email,
    };
    const sendWaitingListSubscription = httpsCallable(
      functions,
      "sendWaitingListSubscription"
    );
    const result: any = await sendWaitingListSubscription(info);
    if (result?.data.success) {
      console.log("Formulario enviado exitosamente");
    } else {
      console.log("Error al enviar formulario: " + result?.data.error);
    }
    try {
      amplitude.track("Email submit");
      const savingData = await addDoc(collection(db, "contact-banner"), {
        datetime: date,
        email: data.email,
      });
      navigate("/waiting-list");
    } catch (err) {
      console.log("Submit failed :", err);
    }
    console.log("submit success");
  };

  return (
    <div className="banner">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={6} xl={5} mb={{ xs: 0, md: 0, xl: 16 }}>
          <Typography variant="h1" component="div">
            {t("banner.title")}
          </Typography>
          <Typography
            variant="h3"
            component="div"
            className="subtitle"
            sx={{ marginBottom: "0px!important" }}
          >
            {t("banner.text")} <span> {t("banner.text-bold1")}</span>{" "}
            {t("banner.text2")} <span>{t("banner.text-bold2")}</span>
          </Typography>
          <Typography
            variant="h3"
            component="div"
            pt={{ xs: 2 }}
            sx={{
              fontWeight: 300,
              lineHeight: "30px",
              marginTop: "0px!important",
            }}
          >
            {t("banner.text3")}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <Grid container mt={7}>
              <Grid item md={7} xs={12} mr={1}>
                <TextField
                  id="outlined-error-helper-text"
                  placeholder={
                    lng === "es" ? "Ingresar email" : "Enter your email"
                  }
                  variant="outlined"
                  className="input-banner"
                  fullWidth
                  {...register("email", {
                    required:
                      lng === "es"
                        ? "Por favor, ingrese su email."
                        : "Please, enter your email.",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message:
                        lng === "es"
                          ? "Por favor, ingrese un email válido"
                          : "Please, enter a valid email",
                    },
                  })}
                  error={!!errors?.email}
                  helperText={!!errors?.email && String(errors?.email?.message)}
                />
                <Typography
                  variant="body2"
                  component="div"
                  className="detail"
                  mt={1}
                >
                  {t("banner.detail-text")}{" "}
                  <Link to={"/terms"} className="terms-link">
                    {t("banner.detail-text-bold")}
                  </Link>
                  {t("banner.detail-text2")}{" "}
                  <Link to={"/privacy-policy"} className="terms-link">
                    {t("banner.detail-text-bold2")}
                  </Link>
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                pt={{ xs: 4, md: 0 }}
                pl={{ xs: 0, md: 2 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  fullWidth
                  sx={{
                    padding: "13px 20px",
                    borderRadius: 3,
                    fontWeight: 700,
                    "@media (max-width: 400px)": {
                      width: "100%",
                    },
                  }}
                >
                  {t("banner.button")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={7}
          sx={
            {
              // display: { xs: "none", md: "inherit" },
            }
          }
          className="cards-container"
        >
          <img className="cards-img" src={cardImage} alt="cards" />
        </Grid>
        {/* <Grid
          item
          xs={12}
          md={6}
          xl={7}
          sx={{
            display: { xs: "block", md: "none" },
          }}
          className="cards-container"
        >
          <img className="cards-img" src={cardImgMobile} alt="cards" />
        </Grid> */}
      </Grid>
    </div>
  );
}

export default Banner;
