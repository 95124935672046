import React, { useEffect, useState } from "react";
import "./TarokoTalks.css";
import Header from "../../components/commons/Header/Header";
import Footer from "../../components/commons/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { usePagination } from "@mantine/hooks";
import {
  Typography,
  Grid,
  Button,
  Fab,
  useMediaQuery,
  Chip,
} from "@mui/material";
import { Pagination } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { getSpotifyToken, getPodcastEpisodes } from "../../service/spotifyApi";
import SubscribeSection from "./SubscribeSection/SubscribeSection";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

export default function TarokoTalks() {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();

  const [token, setToken] = useState("");
  const podcastId = "3mxBnVfs0KFPu2BTgBxu5l";
  const mobile = useMediaQuery(`(max-width: 450px)`);
  const [episodes, setEpisodes] = useState([]);
  const [totalEpisodes, setTotalEpisodes] = useState(0);
  const limit = 8;
  const pagination = usePagination({
    total: Math.ceil(totalEpisodes / limit),
    initialPage: 1,
  });
  const offset = (pagination.active - 1) * limit;

  const ScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getSpotifyToken();
        setToken(accessToken);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    fetchToken();
  }, []);

  useEffect(() => {
    const fetchAllEpisode = async () => {
      if (token) {
        try {
          const episodes = await getPodcastEpisodes(
            podcastId,
            token,
            limit,
            offset
          );

          setEpisodes(episodes.episodes);
          setTotalEpisodes(episodes.totalEpisodes);
        } catch (error) {
          console.error("Error fetching episodes:", error);
        }
      }
    };

    fetchAllEpisode();
  }, [token, pagination.active]);

  const spaceText = (text: string) => {
    text = text.replace(/! /g, "!\n");
    let splitText = text.replace(/       /g, "\n");

    let lineas = splitText.split("\n");

    return lineas;
  };

  return (
    <div className="podcast-back">
      <Header type="w-list" />
      <Grid
        container
        direction="row"
        py={6}
        px={{ xl: 15, md: 8, xs: 2 }}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          color: "#fff",
          backgroundColor: "var(--primary-800)",
        }}
      >
        <Grid item md={12} xs={12}>
          <Typography variant="h3">PODCAST</Typography>
        </Grid>
        <Grid item md={12} xs={12} pt={2}>
          <Typography variant="h1">Taroko Talks</Typography>
        </Grid>
        <Grid item md={12} pt={2} xs={12}>
          <Typography variant="body2">
            {t("taroko-talks.description")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        p={{ xl: 12, xs: 2 }}
        mb={{ md: 10, xs: 4 }}
        pt={10}
        px={{ xl: 15, md: 8, xs: 2 }}
      >
        {episodes?.map(
          (
            ep: {
              id: any;
              description: string;
              name:
                | string
                | number
                | boolean
                | React.ReactElement<
                    any,
                    string | React.JSXElementConstructor<any>
                  >
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined;
            },
            key: number
          ): any => {
            const srcEp = `https://open.spotify.com/embed/episode/${ep?.id}?utm_source=oembed`;
            const hashtags = ep?.description.match(/#[a-zA-Z0-9]+/g);
            const words = hashtags?.map((tag: string) => tag.slice(1));
            const es = "Español";
            const tags = words?.map((tag: string, key) => {
              return (
                <Chip
                  label={tag === "Espa" ? es : tag}
                  key={key}
                  sx={{
                    margin: 0.4,
                    fontWeight: 300,
                  }}
                />
              );
            });

            const goToEpisode = () => {
              navigate(`/taroko-talks/episode?ep=${ep?.id}`);
            };

            return (
              <Grid
                key={key}
                container
                direction="row"
                justifyContent="space-around"
                spacing={4}
                my={{ md: 2, xs: 4 }}
                sx={{
                  backgroundColor: "var(--primary-200)",
                  borderRadius: 4,
                }}
              >
                <Grid
                  item
                  md={7}
                  xs={12}
                  pt={{ xl: 3, md: 2, xs: 0 }}
                  pr={{ xl: 8, md: 6, xs: 0 }}
                  alignSelf="center"
                >
                  <iframe
                    style={{ borderRadius: "12px" }}
                    src={srcEp}
                    width="100%"
                    height="250"
                    frameBorder="0"
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"
                  ></iframe>
                </Grid>
                <Grid
                  item
                  justifyContent="center"
                  md={5}
                  xs={12}
                  pt={{ xl: 3, md: 2, xs: 0 }}
                  mb={{ xs: 0 }}
                >
                  <Grid mb={1}>{tags}</Grid>
                  <Typography variant="body2" pb={2}>
                    {ep.name}
                  </Typography>
                  <Typography
                    pl={{ xl: 0, md: 0, xs: 0 }}
                    px={{ xs: 2 }}
                    variant="body1"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "4",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {spaceText(ep?.description)}
                  </Typography>

                  <Button
                    onClick={goToEpisode}
                    variant="contained"
                    color="primary"
                    fullWidth={mobile ? true : false}
                    sx={{
                      textAlign: "center",
                      // padding: "10px 60px",
                      borderRadius: 3,
                      marginTop: 2,
                      marginBottom: 2,
                      "@media (max-width: 450px)": {
                        padding: "15px ",
                        marginBottom: 2,
                        marginLeft: 0,
                      },
                    }}
                  >
                    {t("taroko-talks.btn-more")}
                    <ChevronRightRoundedIcon
                      fontSize="small"
                      sx={{ paddingLeft: 0.5 }}
                    />
                  </Button>
                </Grid>
              </Grid>
            );
          }
        )}
        <Pagination
          total={pagination.range.length}
          onChange={pagination.setPage}
          styles={(theme) => ({
            item: {
              "&[data-active]": {
                backgroundColor: "var(--primary)",
              },
            },
          })}
        />
      </Grid>
      <SubscribeSection />
      <Fab className="btn-up" color="primary" onClick={ScrollTop}>
        <KeyboardArrowUpIcon />
      </Fab>
      <Footer />
    </div>
  );
}
