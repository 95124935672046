import { Carousel } from "@mantine/carousel";
import { useMediaQuery } from "@mantine/hooks";
import { useMantineTheme, Grid } from "@mantine/core";
import FlipCard from "../../../../components/commons/FlipCards/FlipCards";
export default function CardCarousel({ data }: any) {
  const info = data;
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  const slides = info.map((item: any, key: string) => (
    <Carousel.Slide
      key={key}
      sx={{
        height: 400,
        width: 285,
      }}
    >
      <Grid sx={{ height: "100%" }}>
        <FlipCard
          img={item.img}
          title={item.title}
          description={item.description}
          icon={item.icon}
        />
      </Grid>
    </Carousel.Slide>
  ));

  return (
    <Carousel
      ml={70}
      sx={{
        "@media (max-width: 450px)": {
          marginLeft: 10,
        },
      }}
      withIndicators
      height={400}
      slideSize="20%"
      breakpoints={[
        { maxWidth: "xs", slideSize: "80%", slideGap: "sm" },
        { maxWidth: "md", slideSize: "23%", slideGap: 15 },
      ]}
      slideGap="xl"
      align="start"
      slidesToScroll={mobile ? 1 : 2}
      withControls={true}
      className="prod-carousel"
    >
      {slides}
    </Carousel>
  );
}
