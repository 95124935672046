import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useTranslation } from "react-i18next";
import { ListItem } from "@mui/material";
import { Link } from "react-router-dom";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  ":last-child": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: "var(--bg-beige)",
  paddingTop: 25,
  paddingBottom: 25,
  flexDirection: "row",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 40,
  backgroundColor: "var(--bg-beige)",
  paddingLeft: 25,
}));

export default function CustomizedAccordions() {
  const [t, i18n] = useTranslation("global");
  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel1" ? (
              <RemoveCircleOutlineIcon sx={{ color: "var(--primary)" }} />
            ) : (
              <AddCircleOutlineIcon sx={{ color: "var(--primary)" }} />
            )
          }
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography variant="h3" sx={{ fontWeight: 400 }}>
            {t("faqs.accordion.q1-title")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            {t("faqs.accordion.q1-paragraph1")}
            {""}
            <span>{t("faqs.accordion.q1-paragraph1-bold")}</span>
          </Typography>
          <Typography variant="body1" mt={2}>
            {t("faqs.accordion.q1-paragraph2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel2" ? (
              <RemoveCircleOutlineIcon sx={{ color: "var(--primary)" }} />
            ) : (
              <AddCircleOutlineIcon sx={{ color: "var(--primary)" }} />
            )
          }
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography variant="h3" sx={{ fontWeight: 400 }}>
            {t("faqs.accordion.q2-title")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            {t("faqs.accordion.q2-paragraph1")}
          </Typography>
          <Typography variant="body1" mt={2}>
            {t("faqs.accordion.q2-paragraph2")}
          </Typography>
          <ListItem
            sx={{
              display: "list-item",
              lineHeight: "24px",
              fontSize: "16px",
              fontWeight: 300,
              fontFamily: "Merriweather Sans, sans-serif",
            }}
          >
            {t("faqs.accordion.q2-item1")}
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              lineHeight: "24px",
              fontSize: "16px",
              fontWeight: 300,
              fontFamily: "Merriweather Sans, sans-serif",
            }}
          >
            {t("faqs.accordion.q2-item2")}
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              lineHeight: "24px",
              fontSize: "16px",
              fontWeight: 300,
              fontFamily: "Merriweather Sans, sans-serif",
            }}
          >
            {t("faqs.accordion.q2-item3")}
          </ListItem>
          <Typography variant="body1" mt={2}>
            {t("faqs.accordion.q2-paragraph3")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel3" ? (
              <RemoveCircleOutlineIcon sx={{ color: "var(--primary)" }} />
            ) : (
              <AddCircleOutlineIcon sx={{ color: "var(--primary)" }} />
            )
          }
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography variant="h3" sx={{ fontWeight: 400 }}>
            {t("faqs.accordion.q3-title")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            {t("faqs.accordion.q3-paragraph1")}
          </Typography>
          <ListItem
            sx={{
              display: "list-item",
              lineHeight: "24px",
              fontSize: "16px",
              fontWeight: 300,
              fontFamily: "Merriweather Sans, sans-serif",
            }}
          >
            {t("faqs.accordion.q3-paragraph2")}
          </ListItem>
          <Typography variant="body1" mt={2}>
            {t("faqs.accordion.q3-paragraph3")}
          </Typography>
          <ListItem
            sx={{
              display: "list-item",
              lineHeight: "24px",
              fontSize: "16px",
              fontWeight: 300,
              fontFamily: "Merriweather Sans, sans-serif",
            }}
          >
            {t("faqs.accordion.q3-paragraph4")}
          </ListItem>

          <Typography variant="body1" mt={2}>
            {t("faqs.accordion.q3-paragraph5")}
          </Typography>
          <ListItem
            sx={{
              display: "list-item",
              lineHeight: "24px",
              fontSize: "16px",
              fontWeight: 300,
              fontFamily: "Merriweather Sans, sans-serif",
            }}
          >
            {t("faqs.accordion.q3-paragraph6")}
          </ListItem>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel4" ? (
              <RemoveCircleOutlineIcon sx={{ color: "var(--primary)" }} />
            ) : (
              <AddCircleOutlineIcon sx={{ color: "var(--primary)" }} />
            )
          }
          aria-controls="panel4d-content"
          id="panel4d-header"
        >
          <Typography variant="h3" sx={{ fontWeight: 400 }}>
            {t("faqs.accordion.q4-title")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            {t("faqs.accordion.q4-paragraph1")}
            <Link to="/waiting-list" className="faq-link">
              {t("faqs.accordion.q4-link")}
            </Link>
            {t("faqs.accordion.q4-paragraph2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel5" ? (
              <RemoveCircleOutlineIcon sx={{ color: "var(--primary)" }} />
            ) : (
              <AddCircleOutlineIcon sx={{ color: "var(--primary)" }} />
            )
          }
          aria-controls="panel5d-content"
          id="panel5d-header"
        >
          <Typography variant="h3" sx={{ fontWeight: 400 }}>
            {t("faqs.accordion.q5-title")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            {t("faqs.accordion.q5-paragraph1")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel6" ? (
              <RemoveCircleOutlineIcon sx={{ color: "var(--primary)" }} />
            ) : (
              <AddCircleOutlineIcon sx={{ color: "var(--primary)" }} />
            )
          }
          aria-controls="panel6d-content"
          id="panel6d-header"
        >
          <Typography variant="h3" sx={{ fontWeight: 400 }}>
            {t("faqs.accordion.q6-title")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            {t("faqs.accordion.q6-paragraph1")}
          </Typography>
          <Typography variant="body1" mt={2}>
            {t("faqs.accordion.q6-paragraph2")}
          </Typography>
          <Typography variant="body1" mt={2}>
            {t("faqs.accordion.q6-paragraph3")}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
