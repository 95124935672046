import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { rolOptionEs, rolOptionEn } from "./selectorsConstants";

// function getStyles(rol: string, rolOption: string[], theme: Theme) {
//   return {
//     fontWeight:
//       rolOption.indexOf(rol) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export default function SelectLabels({ onSelection, error }: any) {
  const [t, i18n] = useTranslation("global");
  const [rol, setRol] = React.useState("");

  const lng = i18n.resolvedLanguage;
  const rolOption = lng === "es" ? rolOptionEs : rolOptionEn;

  const handleChange = (event: SelectChangeEvent) => {
    setRol(event.target.value);
    onSelection(event.target.value);
  };
  const errorLine = error ? "Mui-error" : "input-select";
  return (
    <>
      <Select
        value={rol}
        onChange={handleChange}
        displayEmpty
        fullWidth
        className={errorLine}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value="">
          <em>{t("waiting-list.rol-default")}</em>
        </MenuItem>
        {rolOption.map((rol) => (
          <MenuItem key={rol} value={rol}>
            {rol}
          </MenuItem>
        ))}
      </Select>
      {/* <FormHelperText>Without label</FormHelperText> */}
    </>
  );
}
