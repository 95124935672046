import React, { useEffect } from "react";
import Header from "../../components/commons/Header/Header";
import Footer from "../../components/commons/Footer/Footer";
import { Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Terms() {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="contact-form">
      <Header />
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        mt={20}
        mb={10}
        p={{ xl: 12 }}
        px={{ xl: 15, xs: 2 }}
      >
        <Grid item sm={8}>
          <Typography variant="h2" mb={4}>
            {t("terms.main-title")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.main-title-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("terms.preventions")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.preventions-text")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.preventions-text2")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.preventions-text3")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.preventions-text4")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.preventions-text5")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.preventions-text6")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.preventions-text7")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("terms.service-scope")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.service-scope-text")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.service-scope-text2")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.service-scope-text3")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("terms.investment-risks")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.investment-risks-text")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.investment-risks-text2")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.investment-risks-text3")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.investment-risks-text4")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.investment-risks-text5")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("terms.user-behavior")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.user-behavior-text")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.user-behavior-text2")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.user-behavior-text3")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.user-behavior-text4")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.user-behavior-text5")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.user-behavior-text6")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("terms.intellectual-property")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.intellectual-property-text")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.intellectual-property-text2")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.intellectual-property-text3")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("terms.modifications")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.modifications-text")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("terms.modifications-text2")}
          </Typography>
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}
