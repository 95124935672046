import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";

const fallbackLng = ["en"];
const availableLng = ["es", "en"];
const options = {
  // order and from where user language should be detected
  order: [
    "querystring",
    "navigator",
    "querystring",
    "htmlTag",
    "path",
    "subdomain",
  ],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  // lookupCookie: "i18next",
  // lookupLocalStorage: "i18nextLng",
  // lookupSessionStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  // caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: "myDomain",

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: "/", sameSite: "strict" },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng,
    whitelist: availableLng,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      es: { global: global_es },
      en: { global: global_en },
    },
    detection: options,
  });

export default i18n;
