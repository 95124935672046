import React from "react";
import { Typography, Grid } from "@mui/material";
import CarouselFeature from "./Carousel/Carousel";
import CarouselMobile from "./Carousel/CarouselMobile";
import ImageInfo from "./ImageInfo";
import { useTranslation } from "react-i18next";
import "./Features.css";

function Features() {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="features" id="features">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        pt={{ md: 14, xs: 6 }}
        pl={{ md: 14, xs: 2 }}
        pb={0}
      >
        <Grid item xs={12} md={8} xl={6} pr={{ md: 0, xs: 2 }}>
          <Typography variant="h2" component="div">
            {t("features.title")}
          </Typography>
          <Typography variant="h3" component="div" className="subtitle">
            {t("features.text")}
            {""}
            <span>{t("features.text-bold")}</span> {t("features.text2")}
          </Typography>
        </Grid>
        <Grid item mt={8} md={12} sx={{ display: { xs: "none", md: "block" } }}>
          <CarouselFeature />
        </Grid>
        <Grid item mt={8} xs={12} sx={{ display: { xs: "block", md: "none" } }}>
          <CarouselMobile />
        </Grid>
      </Grid>
    </div>
  );
}

export default Features;
