import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  FormHelperText,
} from "@mui/material";
import * as amplitude from "@amplitude/analytics-browser";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import InputSelectorRol from "./components/InputSelectorRol";
import InputSelectorProd from "./components/InputSelectorProd";
import CheckBoxValidate from "./components/CheckBoxValidate";
import "./WaitingListForm.css";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Label = styled("div")`
  color: var(--text-pramary);
  font-size: 14px;
  margin-bottom: 10px;
  font-family: Merriweather, serif;
  font-weight: 400;
`;

export default function ContactForm({ onSubmit }: any) {
  const [t, i18n] = useTranslation("global");
  const lng = i18n.resolvedLanguage;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [formValues, setFormValues] = React.useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    rol: "",
    product: "",
    terms: true,
  });
  const [value, setValue] = useState<string>();

  const [errorRol, setErrorRol] = React.useState(false);
  const emptyError = Object.keys(errors).length !== 0;

  const isEmpty = () => {
    if (
      formValues.name !== "" &&
      formValues.lastName !== "" &&
      formValues.email !== "" &&
      formValues.phone !== "" &&
      formValues.rol !== "" &&
      formValues.terms === true
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const submitValues = (values: any) => {
    if (errorRol) {
      setErrorRol(true);
    } else {
      onSubmit({ ...formValues, ...values });
      setBtnDisabled(true);
      amplitude.track("Submit waiting list");
    }
  };

  useEffect(() => {
    setFormValues({ ...formValues, phone: value || "" });
  }, [value]);

  useEffect(() => {
    isEmpty();
  }, [formValues]);

  const onSelectionRol = (item: any) => {
    setFormValues({ ...formValues, rol: item });
  };
  const onSelectionProd = (item: any) => {
    setFormValues({ ...formValues, product: item });
  };
  const checkedTerms = (item: any) => {
    setFormValues({ ...formValues, terms: item });
  };
  useEffect(() => {
    if (emptyError && !formValues.rol) {
      setErrorRol(true);
    }
    if (formValues.rol) {
      setErrorRol(false);
    }
  }, [errors, formValues]);

  return (
    <form className="contact-form" onSubmit={handleSubmit(submitValues)}>
      <Typography variant="h2" component="div">
        {t("waiting-list.title")}
      </Typography>
      <Typography variant="h3" component="div" className="subtitle">
        {t("waiting-list.text")}
      </Typography>
      <Grid container justifyContent="space-between">
        <Grid item pb={{ md: 3, xs: 3 }} pr={{ md: 2, xs: 0 }} xs={12} md={6}>
          <Label>{t("waiting-list.name")}</Label>
          <TextField
            id="outlined-error-helper-text"
            placeholder={lng === "es" ? "Primer nombre" : "First Name"}
            className="input-banner"
            variant="outlined"
            fullWidth
            {...register("name", {
              required:
                lng === "es"
                  ? "Por favor, ingrese su nombre."
                  : "Please, enter your first name.",
            })}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
            error={!!errors?.name}
            helperText={!!errors?.name && String(errors?.name?.message)}
          />
        </Grid>
        <Grid item pb={{ md: 3, xs: 3 }} xs={12} md={6}>
          <Label>{t("waiting-list.last-name")}</Label>
          <TextField
            id="outlined-error-helper-text"
            placeholder={lng === "es" ? "Apellido" : "Last Name"}
            className="input-banner"
            variant="outlined"
            fullWidth
            {...register("lastName", {
              required:
                lng === "es"
                  ? "Por favor, ingrese su apellido."
                  : "Please, enter your last name.",
            })}
            onChange={(e) =>
              setFormValues({ ...formValues, lastName: e.target.value })
            }
            error={!!errors?.lastName}
            helperText={!!errors?.lastName && String(errors?.lastName?.message)}
          />
        </Grid>

        <Grid item pb={{ md: 3, xs: 3 }} xs={12}>
          <Label>{t("waiting-list.email")}</Label>
          <TextField
            id="outlined-error-helper-text"
            placeholder={
              lng === "es" ? "ejemplo@empresa.com" : "example@company.com"
            }
            variant="outlined"
            className="input-banner"
            fullWidth
            {...register("email", {
              required:
                lng === "es"
                  ? "Por favor ingrese su email."
                  : "Please, enter your email.",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message:
                  lng === "es"
                    ? "Por favor, ingrese un email válido."
                    : "Please, enter a valid email",
              },
            })}
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
            error={!!errors?.email}
            helperText={!!errors?.email && String(errors?.email?.message)}
          />
        </Grid>
        <Grid item pb={{ md: 3, xs: 3 }} xs={12}>
          <Label>{t("waiting-list.phone")}</Label>
          <PhoneInput
            international
            defaultCountry="MX"
            placeholder="Enter phone number"
            value={formValues.phone}
            onChange={setValue}
          />
          {/* <TextField
            id="outlined-error-helper-text"
            placeholder="+54 1123456789"
            variant="outlined"
            className="input-banner"
            type="number"
            fullWidth
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            {...register("phone", {
              required:
                lng === "es"
                  ? "Por favor, ingrese su teléfono."
                  : "Please, enter yout phone number.",
              pattern: {
                value: /^[0-9\b]+$/,
                message:
                  lng === "es"
                    ? "Por favor, ingrese un numero válido."
                    : "Please, enter a valid number.",
              },
            })}
            onChange={(e) =>
              setFormValues({ ...formValues, phone: e.target.value })
            }
            error={!!errors?.phone}
            helperText={!!errors?.phone && String(errors?.phone?.message)}
          /> */}
        </Grid>
        <Grid item pb={{ md: 3, xs: 3 }} xs={12}>
          <Label>{t("waiting-list.rol")}</Label>
          <InputSelectorRol onSelection={onSelectionRol} error={errorRol} />
          {errorRol && (
            <FormHelperText sx={{ color: "var(--error)" }}>
              {t("waiting-list.error-rol")}
            </FormHelperText>
          )}
        </Grid>
        <Grid item pb={{ md: 3, xs: 3 }} xs={12}>
          <Label>{t("waiting-list.prods")}</Label>
          <InputSelectorProd onSelection={onSelectionProd} />
        </Grid>
        <Grid item pb={{ md: 3, xs: 3 }} xs={12}>
          <Grid container direction="row">
            <CheckBoxValidate checkedTerms={checkedTerms} register={register} />
            {lng === "es" && (
              <Link to="/terms" className="terms-link">
                <Typography
                  variant="body1"
                  sx={{ marginTop: "10px" }}
                  component="div"
                >
                  {t("waiting-list.legals-link2")}
                </Typography>
              </Link>
            )}
            {lng === "en" && (
              <Typography variant="body1" sx={{ marginTop: "10px" }}>
                <Link to="/terms" className="terms-link">
                  {t("waiting-list.legals-link1")}
                  {""}
                </Link>
                {t("waiting-list.legals-text")}
                <Link to="/private-policy" className="terms-link">
                  {t("waiting-list.legals-link2")}
                </Link>
              </Typography>
            )}
          </Grid>
          {!formValues.terms && (
            <FormHelperText sx={{ color: "var(--error)" }}>
              {t("waiting-list.error-terms")}
            </FormHelperText>
          )}
        </Grid>
        <Grid item pb={{ md: 3, xs: 3 }} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            fullWidth
            disabled={btnDisabled}
            sx={{
              padding: "13px 20px",
              fontSize: "16px",
              borderRadius: 3,
              "@media (max-width: 450px)": {
                width: "100%",
              },
            }}
          >
            {t("waiting-list.button")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
