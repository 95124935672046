import React, { useEffect } from "react";
import Header from "../../components/commons/Header/Header";
import Footer from "../../components/commons/Footer/Footer";
import { Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Declaimer() {
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="contact-form">
      <Header />
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        mt={20}
        mb={10}
        p={{ xl: 12 }}
        px={{ xl: 15, xs: 2 }}
      >
        <Grid item sm={8}>
          <Typography variant="h2" mb={2}>
            {t("declaimer.main-title")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("declaimer.main-title-text")}
          </Typography>
          <Typography variant="h2" mb={2}>
            {t("declaimer.warning")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("declaimer.value")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("declaimer.value-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("declaimer.projection")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("declaimer.projection-text")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("declaimer.projection-text2")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("declaimer.invest-liquidity")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("declaimer.invest-liquidity-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("declaimer.regulations")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("declaimer.regulations-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("declaimer.taxes")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("declaimer.taxes-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("declaimer.investment-risks")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("declaimer.investment-risks-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("declaimer.investment-term")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("declaimer.investment-term-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("declaimer.customer-classification")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("declaimer.customer-classification-text")}
          </Typography>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
