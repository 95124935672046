import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import Header from "../../components/commons/Header/Header";
import Footer from "../../components/commons/Footer/Footer";
import styled from "@emotion/styled";
import WaitingListForm from "./WaitingListForm/WaitingListForm";
import Thanks from "./Thanks/Thanks";
import { db, functions } from "../../firebase";
import { collection, doc, addDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import "./WaitingList.css";
var moment = require("moment");

const Image = styled("img")`
  max-height: 770px;
  padding-right: 100px;
`;

export default function WaitingList() {
  const [values, setValues] = useState({});
  var date = moment().format();
  const initValue = Object.keys(values).length === 0;
  const hadValue = Object.keys(values).length !== 0;

  const onSubmit = async (data: any) => {
    if (data.terms == false) {
      return;
    } else {
      const info = {
        datetime: date,
        name: data.name,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        rol: data.rol,
        product: data.product,
        acceptedTerms: data.terms,
      };
      const sendWaitingListData = httpsCallable(
        functions,
        "sendWaitingListData"
      );
      try {
        const savingData = await addDoc(collection(db, "waiting-list"), info);

        setValues(data);
        const result: any = await sendWaitingListData(info);
        if (result?.data.success) {
          console.log("Formulario enviado exitosamente");
        } else {
          console.log("Error al enviar formulario: " + result?.data.error);
        }
      } catch (err) {
        console.log("Submit failed :", err);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [values]);

  return (
    <div className="waiting-list">
      <Header type="w-list" />
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        p={{ md: 9, xs: 3, xl: 12 }}
        px={{ xl: 15 }}
      >
        <Grid item md={5} xl={4} xs={12}>
          {initValue && <WaitingListForm onSubmit={onSubmit} />}
          {hadValue && <Thanks />}
        </Grid>
        <Grid
          item
          md={6}
          p={2}
          justifyContent="flex-end"
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          <Image src="./img/girl-coffee.png" />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
