import React from "react";
import { Typography, Grid } from "@mui/material";
import { mainText } from "./ThanksConstants";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const Image = styled("img")`
  width: 56px;
  height: 56px;
`;

export default function Thanks() {
  const [t, i18n] = useTranslation("global");
  return (
    <Grid
      container
      justifyContent="center"
      pb={6}
      sx={{ textAlign: "center", margin: "0px auto" }}
    >
      <Grid item md={12} pb={3}>
        <Image src="./img/icons/check-outline.gif" />
      </Grid>
      <Grid item md={7}>
        <Typography variant="subtitle2" pb={3}>
          {t("thanks.title")}
        </Typography>
        <Typography variant="body1">{t("thanks.text")}</Typography>
      </Grid>
    </Grid>
  );
}
