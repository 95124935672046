import React, { useEffect, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Alert,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { collection, doc, addDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import "./TarokoTalks.css";

var moment = require("moment");

function TarokoTalks() {
  const [t, i18n] = useTranslation("global");
  const [open, setOpen] = React.useState(false);
  const lng = i18n.resolvedLanguage;

  const mobile = useMediaQuery(`(max-width: 450px)`);
  const spotifyUrl =
    "https://open.spotify.com/show/3mxBnVfs0KFPu2BTgBxu5l?si=8d3e616598ff4ab5";

  const applePodcastUrl =
    "https://podcasts.apple.com/us/podcast/taroko-talks/id1716340338";

  return (
    <div className="banner-talks">
      <Grid container>
        <Grid item md={5}>
          <Typography variant="h2" component="div" mb={{ md: 4, xs: 2 }}>
            {t("header.taroko-talks")}
          </Typography>
          <Typography variant="h3" className="subtitle">
            {t("taroko-talks.about-text")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth={mobile ? true : false}
            sx={{
              textAlign: "left",
              marginTop: "30px",
              padding: "13px 20px",
              borderRadius: 3,
              "@media (max-width: 450px)": {
                marginTop: "30px",
                alignItems: "center",
                textAlign: "center",
              },
            }}
            href="/taroko-talks"
          >
            {t("podcast.button")}

            <img
              src="./img/icons/audio-icon.svg"
              alt="audio"
              style={{ marginLeft: "8px" }}
            />
          </Button>
        </Grid>
        <Grid item mt={4} xs={6} md={12}>
          <Link to={applePodcastUrl} target="_blank">
            <img
              width={160}
              src="../img/US-UK_Apple_Podcasts_Listen_Badge.svg"
            />
          </Link>
        </Grid>
        <Grid item mt={4} md={12} xs={6}>
          <Link to={spotifyUrl} target="_blank">
            <img src="../img/spotify-badge.svg" width={160} />
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

export default TarokoTalks;
