export const prodOptionsEs = [
  "Real Estate",
  "Energías Renovables",
  "Tierras",
  "Capital Privado (Private Equity)",
  "Capital de Riesgo (Venture Capital)",
  "Fondos de Cobertura (Hedge Funds)",
  "Activos Digitales",
  "Crédito Privado",
  "Arte & Objetos de colección",
  "Financiamiento de litigios ",
];
export const rolOptionEs = [
  "Gestor de patrimonio",
  "Asesor financiero",
  "Inversor",
  "Firma de inversión",
];

export const prodOptionsEn = [
  "Real Estate",
  "Renewable Energy",
  "Land",
  "Private Equity",
  "Venture Capital",
  "Hedge Funds",
  "Digital Assets",
  "Private Credit",
  "Art & Collectibles",
  "Litigation Finance",
];
export const rolOptionEn = [
  "Wealth Manager",
  "Financial Advisor",
  "Investor",
  "Asset Manager",
];
