export const productsEs = [
  {
    title: "Private Equity",
    description:
      "Invierte en fondos de capital privado de alta calidad enfocados en empresas con gran potencial de crecimiento.",
    img: "private-equity.png",
    icon: "private-equity-icon.svg",
  },
  {
    title: "Venture Capital",
    description:
      "Invierte en fondos de VC de primer nivel enfocados en start-ups innovadoras con gran potencial de rendimiento.",
    img: "prod-vc.png",
    icon: "vc-icon.svg",
  },
  {
    title: "Hedge Funds",
    description:
      "Invierta en múltiples estrategias de hedge funds que generan rendimientos consistentes y atractivos ajustados al riesgo.",
    img: "hedge-funds.png",
    icon: "hedge-funds-icon.svg",
  },
  {
    title: "Real Estate",
    description:
      "Invierte en oportunidades inmobiliarias seleccionadas, para aprovechar el potencial de crecimiento a largo plazo.",
    img: "real-state.png",
    icon: "real-state-icon.svg",
  },
  {
    title: "Private Credit",
    description:
      "Invierte en oportunidades de préstamos directos que abarquen todo el espectro de crédito.",
    img: "private-credit.png",
    icon: "private-credit-icon.svg",
  },
  {
    title: "Infraestructura",
    description:
      "Invierte en fondos de primer nivel enfocados en proyectos sostenibles para un futuro más verde, como parques eólicos y solares.",
    img: "energia-card.png",
    icon: "energia-renobable-icon.svg",
  },
];

export const productsEn = [
  {
    title: "Private Equity",
    description:
      "Invest in top quality PE funds targeting companies with substantial growth potential.",
    img: "private-equity.png",
    icon: "private-equity-icon.svg",
  },
  {
    title: "Venture Capital",
    description:
      "Invest in top-tier VC funds targeting innovative start-ups with potential of generating high returns.",
    img: "prod-vc.png",
    icon: "private-equity-icon.svg",
  },
  {
    title: "Hedge Funds",
    description:
      "Invest in multi-strategy hedge funds generating consistent and attractive risk-adjusted returns.",
    img: "hedge-funds.png",
    icon: "hedge-funds-icon.svg",
  },
  {
    title: "Real Estate",
    description:
      "Invest in curated RE opportunities, including value-add and opportunistic deals, to harness long-term growth potential.",
    img: "real-state.png",
    icon: "real-state-icon.svg",
  },
  {
    title: "Private Credit",
    description:
      "Invest in direct lending opportunities spanning the entire credit spectrum.",
    img: "private-credit.png",
    icon: "private-credit-icon.svg",
  },
  {
    title: "Infrastructure",
    description:
      "Invest in first-class funds targeting sustainable projects for a greener future, such as wind and solar farms.",
    img: "energia-card.png",
    icon: "energia-renobable-icon.svg",
  },
];
