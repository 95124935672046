import React from "react";
import { Typography, Grid } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Footer.css";

interface Props {
  type?: string;
}
function Footer(props: Props) {
  const [t, i18n] = useTranslation("global");
  const env = process.env.REACT_APP_SPOTIFY_ENV;
  const linksLanding = (
    <Grid
      py={{ md: 2 }}
      mb={5}
      container
      sx={{
        color: "#fff",
      }}
    >
      <Grid item xl={1} md={2} xs={12} py={{ md: 0, xs: 1 }}>
        <HashLink smooth to="/our-platform" className="nav-link">
          <Typography variant="body2">{t("header.how-works")}</Typography>
        </HashLink>
      </Grid>
      <Grid item xl={2} md={2} xs={12} py={{ md: 0, xs: 1 }}>
        <HashLink smooth to="/taroko-talks" className="nav-link">
          <Typography variant="body2">{t("header.taroko-talks")}</Typography>
        </HashLink>
      </Grid>
    </Grid>
  );

  const links = (
    <Grid
      py={{ md: 2 }}
      mb={5}
      container
      sx={{
        color: "#fff",
      }}
    >
      <Grid item md={2} xs={12} py={{ md: 0, xs: 1 }}>
        <HashLink to="/our-platform" className="nav-link">
          <Typography variant="body2">{t("header.how-works")}</Typography>
        </HashLink>
      </Grid>
      <Grid item md={2} xs={12} py={{ md: 0, xs: 1 }}>
        <HashLink to="/taroko-talks" className="nav-link">
          <Typography variant="body2">{t("header.taroko-talks")}</Typography>
        </HashLink>
      </Grid>
    </Grid>
  );

  const showLinks = props.type === "landing" ? linksLanding : links;

  return (
    <footer>
      <div className="footer">
        <Grid
          container
          sx={{
            color: "#fff",
          }}
        >
          <Grid item md={4} py={2}>
            <Typography variant="h2" pb={2}>
              Taroko
            </Typography>
            <Typography variant="body1">{t("footer.text")}</Typography>
          </Grid>
        </Grid>

        {showLinks}
        <Grid
          item
          md={2}
          textAlign="left"
          sx={{
            display: { xs: "inherit", md: "none" },
          }}
        >
          <Link
            to="https://www.linkedin.com/company/taroko-investments"
            target="_blank"
            className="nav-link"
          >
            <img src={`${env}/img/icons/linkedin-w.svg`} alt="linkedin" />
          </Link>
        </Grid>
        <Grid
          container
          mt={2}
          pt={3}
          sx={{
            color: "#fff",
            borderTop: "solid 1px #33493E",
          }}
        >
          <Grid item md={4} xs={12} py={{ md: 0, xs: 1 }}>
            <Typography variant="body1">
              © 2024 Taroko. All rights reserved.
            </Typography>
          </Grid>
          <Grid item md={2} xs={12} py={{ md: 0, xs: 1 }}>
            <Link to="/privacy-policy" className="nav-link">
              <Typography variant="body2">
                {t("footer.privacy-policy")}
              </Typography>
            </Link>
          </Grid>
          <Grid item md={2} xs={12} py={{ md: 0, xs: 1 }}>
            <Link to="/terms" className="nav-link">
              <Typography variant="body2">{t("footer.terms")}</Typography>
            </Link>
          </Grid>
          <Grid item md={2} xs={12} py={{ md: 0, xs: 1 }}>
            <Link to="/declaimer" className="nav-link">
              <Typography variant="body2">{t("footer.declaimer")}</Typography>
            </Link>
          </Grid>
          <Grid
            item
            md={2}
            textAlign="right"
            py={{ md: 0, xs: 1 }}
            sx={{
              display: { xs: "none", md: "block" },
            }}
          >
            <Link
              to="https://www.linkedin.com/company/taroko-investments"
              target="_blank"
              className="nav-link"
            >
              <img src={`${env}/img/icons/linkedin-w.svg`} alt="linkedin" />
            </Link>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}

export default Footer;
