import * as amplitude from "@amplitude/analytics-browser";
import "./Faqs.css";
import { Typography, Grid, Button } from "@mui/material";
import CustomizedAccordions from "./Accordion/Accordion";
import { useTranslation } from "react-i18next";

function Faqs() {
  const [t, i18n] = useTranslation("global");
  const trackClickContact = () => {
    amplitude.track("click to Contact button");
  };
  return (
    <div className="faqs" id="faqs">
      <Grid container justifyContent="center" my={{ md: 8, xs: 4 }}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h2" pb={2}>
            {t("faqs.title")}
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 400 }}>
            {t("faqs.text")}
          </Typography>
        </Grid>
        <Grid item md={8} xs={12} my={{ md: 6, xs: 4 }}>
          <CustomizedAccordions />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Grid item xs={12} className="cta-container" p={{ md: 6, xs: 3 }}>
            <Typography
              variant="h2"
              component="div"
              className="cta-title"
              sx={{
                fontSize: 20,
              }}
            >
              {t("faqs.cta.title")}
            </Typography>
            <Typography
              variant="h3"
              component="div"
              className="subtitle"
              sx={{
                fontSize: 18,
              }}
            >
              {t("faqs.cta.text")}
            </Typography>
            <Button
              onClick={trackClickContact}
              sx={{
                color: "#001B0E",
                padding: "13px 20px",
                borderRadius: 3,
                textDecoration: "none",
              }}
              variant="contained"
              color="secondary"
              size="large"
              href="mailto:info@taroko.la"
            >
              {t("faqs.cta.button")}
              <img src="./img/icons/message-chat-circle.svg" alt="email" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Faqs;
