import { Typography, Button, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Platform.css";

function LastEpisode() {
  const [t, i18n] = useTranslation("global");
  const mobile = useMediaQuery(`(max-width: 450px)`);

  return (
    <div className="platform">
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item md={5} xs={12} pr={{ md: 6 }}>
          <img
            src="./img/icons/trending-up.svg"
            alt="arrow"
            style={{
              marginBottom: 24,
              borderRadius: 50,
              padding: 12,
              border: "10px solid var(--bg-light)",
              backgroundColor: "var(--white-50)",
            }}
          />
          <Typography variant="h2" component="div">
            {t("taroko-platform")}
          </Typography>
          <Typography variant="h3" className="subtitle" component="div">
            {t("features.text")}
            {t("features.text-bold")}
            {t("features.text2")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth={mobile ? true : false}
            sx={{
              textAlign: "left",
              marginTop: "30px",
              padding: "13px 20px",
              borderRadius: 3,
            }}
            href="/our-platform"
          >
            {t("about-platform")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default LastEpisode;
