import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";

export default function CheckBoxValidate({ checkedTerms }: any) {
  const [t, i18n] = useTranslation("global");
  const lng = i18n.resolvedLanguage;

  const [state, setState] = React.useState({
    terms: true,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    checkedTerms(event.target.checked);
  };

  const { terms } = state;
  const error = terms === false;
  const labelText = lng == "es" ? "Acepto los" : "I have read and accept the";

  return (
    <FormControl required error={error} component="fieldset" variant="standard">
      <FormGroup>
        <FormControlLabel
          labelPlacement="end"
          id="check-terms"
          control={
            <Checkbox checked={terms} onChange={handleChange} name="terms" />
          }
          label={labelText}
        />
      </FormGroup>
      {/* {error && <FormHelperText>Acepte los terminos</FormHelperText>} */}
    </FormControl>
  );
}
