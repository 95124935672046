import React from "react";
import * as amplitude from "@amplitude/analytics-browser";
import "./CtaSection.css";
import { Typography, Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function CtaSection() {
  const [t, i18n] = useTranslation("global");
  const trackClickCta = () => {
    amplitude.track("click CTA to waiting list");
  };
  return (
    <div className="cta-section">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        px={{ xs: 3, md: 8 }}
        py={{ xs: 3, md: 8 }}
        sx={{
          color: "#fff",
          textAlign: "center",
        }}
      >
        <Grid item xs={12} className="cta-container" p={{ md: 9, xs: 3 }}>
          <Typography variant="h2" component="div" className="cta-title">
            {t("cta-section.title")}
          </Typography>
          <Typography variant="h3" component="div" className="subtitle">
            {t("cta-section.text")}
          </Typography>
          <Link to={"/waiting-list"} className="nav-link">
            <Button
              onClick={trackClickCta}
              sx={{
                color: "#001B0E",
                padding: "13px 20px",
                borderRadius: 3,
                "@media (max-width: 450px)": {
                  width: "100%",
                },
              }}
              variant="contained"
              color="secondary"
              size="large"
            >
              {t("banner.button")}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

export default CtaSection;
