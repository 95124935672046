import React, { useEffect, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { collection, doc, addDoc } from "firebase/firestore";
import { db, functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import "./Banner.css";

var moment = require("moment");

function Banner() {
  const [t, i18n] = useTranslation("global");
  const [open, setOpen] = React.useState(false);
  const lng = i18n.resolvedLanguage;

  const navigate = useNavigate();
  var date = moment().format();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const trackClickCta = () => {
    amplitude.track("click CTA to subcribe Podcast");
  };

  const onSubmit = async (data: any) => {
    const info = {
      datetime: date,
      email: data.email,
      section: "Banner Inicio",
    };
    const sendDataSubscribe = httpsCallable(functions, "sendDataSubscribe");
    try {
      amplitude.track("Email submit");
      showSuccess();
      const savingData = await addDoc(
        collection(db, "subscribe-podcast"),
        info
      );
      const result: any = await sendDataSubscribe(info);
      if (result?.data.success) {
        console.log("Formulario enviado exitosamente");
      } else {
        console.log("Error al enviar formulario: " + result?.data.error);
      }
    } catch (err) {
      console.log("Submit failed :", err);
    }
    console.log("submit success");
  };

  const showSuccess = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="banner-podcast">
      <Grid container justifyContent="center">
        <Grid item xl={10} md={12}>
          <Typography variant="h1" textAlign="center">
            {t("taroko-talks.banner.title")}
          </Typography>
          <Typography variant="h3" pt={4} textAlign="center">
            {t("taroko-talks-platform")}
          </Typography>
          <Typography
            variant="h3"
            className="subtitle"
            pb={3}
            textAlign="center"
          >
            {t("taroko-talks.banner.subtitle")}
          </Typography>
        </Grid>
        <Grid item xl={8} md={6} justifyContent="center">
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <Grid container justifyContent="center">
              <Grid item md={6} xs={12} mr={{ md: 1, xs: 0 }}>
                <TextField
                  id="outlined-error-helper-text"
                  placeholder={
                    lng === "es" ? "Ingresar email" : "Enter your email"
                  }
                  variant="outlined"
                  className="input-banner"
                  fullWidth
                  {...register("email", {
                    required:
                      lng === "es"
                        ? "Por favor, ingrese su email."
                        : "Please, enter your email.",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message:
                        lng === "es"
                          ? "Por favor, ingrese un email válido"
                          : "Please, enter a valid email",
                    },
                  })}
                  error={!!errors?.email}
                  helperText={!!errors?.email && String(errors?.email?.message)}
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                pl={{ xs: 0, md: 2 }}
                mt={{ md: 0, xs: 3 }}
              >
                <Button
                  onClick={trackClickCta}
                  size="large"
                  type="submit"
                  fullWidth
                  sx={{
                    // color: "#001B0E",
                    padding: "13px 20px",
                    borderRadius: 3,
                    "@media (max-width: 400px)": {
                      width: "100%",
                    },
                  }}
                  variant="contained"
                  color="primary"
                >
                  {t("taroko-talks.subscribe")}
                </Button>
              </Grid>
            </Grid>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                variant="filled"
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {t("taroko-talks.succsess")}
              </Alert>
            </Snackbar>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}

export default Banner;
