import { Typography, Grid } from "@mui/material";
import CardCarousel from "./CardCarousel/CardCarousel";
import { useTranslation } from "react-i18next";
import { productsEs, productsEn } from "./ProductsConstants";
import "./Products.css";

function Features() {
  const [t, i18n] = useTranslation("global");
  const lng = i18n.resolvedLanguage;
  const products = lng === "es" ? productsEs : productsEn;

  return (
    <div className="products">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        pt={{ md: 8, xs: 6 }}
        px={{ md: 8, xs: 1 }}
        sx={{
          overflow: "hidden",
        }}
      >
        <Grid item xs={12} md={9} xl={6} pr={{ md: 0, xs: 0 }}>
          <Typography variant="h2">{t("products.title")}</Typography>
          {lng === "es" && (
            <Typography variant="h3" className="subtitle">
              {t("products.text")} <span>{t("products.text-bold")}</span>
            </Typography>
          )}
          {lng === "en" && (
            <Typography variant="h3" className="subtitle">
              <span>{t("products.text-bold")}</span>
              {t("products.text")}
            </Typography>
          )}
        </Grid>
        {/* <Grid
          item
          md={12}
          xl={12}
          justifyContent="space-between"
          flexDirection="row"
          sx={{
            display: { xs: "none", md: "flex", lg: "flex" },
            height: { md: "400px" },
          }}
        >
          {products.map((item: any, key: any) => {
            return (
              <Grid
                sx={{
                  height: 400,
                  width: 300,
                }}
                key={key}
              >
                <FlipCard
                  img={item.img}
                  title={item.title}
                  description={item.description}
                  icon={item.icon}
                />
              </Grid>
            );
          })}
        </Grid> */}
      </Grid>
      <CardCarousel data={products} />
    </div>
  );
}

export default Features;
