import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { prodOptionsEs, prodOptionsEn } from "./selectorsConstants";

// function getStyles(rol: string, rolOption: string[], theme: Theme) {
//   return {
//     fontWeight:
//       rolOption.indexOf(rol) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export default function SelectLabels({ onSelection }: any) {
  const [t, i18n] = useTranslation("global");
  const [prod, setProd] = React.useState("");
  const lng = i18n.resolvedLanguage;
  const prodOptions = lng == "es" ? prodOptionsEs : prodOptionsEn;

  const handleChange = (event: SelectChangeEvent) => {
    setProd(event.target.value);
    onSelection(event.target.value);
  };

  return (
    <>
      <Select
        value={prod}
        onChange={handleChange}
        displayEmpty
        fullWidth
        className="input-banner"
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value="">
          <em>{t("waiting-list.prods-default")}</em>
        </MenuItem>
        {prodOptions.map((prod) => (
          <MenuItem key={prod} value={prod}>
            {prod}
          </MenuItem>
        ))}
      </Select>
      {/* <FormHelperText>Without label</FormHelperText> */}
    </>
  );
}
