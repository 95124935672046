import React from "react";
import "./Platform.css";
import Banner from "./Banner/Banner";
import Features from "./Features/Features";
import Numbers from "./Numbers/Numbers";
import Products from "./Products/Products";
import CtaSection from "./CtaSection/CtaSection";
import Footer from "../../components/commons/Footer/Footer";
import Faqs from "./Faqs/Faqs";
import Header from "../../components/commons/Header/Header";

function Platform() {
  return (
    <div className="landing">
      <Header type="landing" />
      <Banner />
      <Features />
      <Numbers />
      <Products />
      <CtaSection />
      <Faqs />
      <Footer type="landing" />
    </div>
  );
}

export default Platform;
