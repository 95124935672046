import React from "react";
import "./Numbers.css";
import { Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { featureDataEs, featureDataEn } from "./NumberConstants";

function Features() {
  const [t, i18n] = useTranslation("global");
  const lng = i18n.resolvedLanguage;

  const dataNumbers = lng === "es" ? featureDataEs : featureDataEn;
  return (
    <div className="numbers-section">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        px={{ md: 8, xs: 0 }}
        pt={{ md: 8, xs: 7 }}
        sx={{
          color: "#fff",
          textAlign: "center",
        }}
      >
        <Grid item xs={12} mt={0} mb={{ xl: 6, sm: 2, xs: 2 }}>
          <Typography variant="h3">{t("numbers.title")}</Typography>
        </Grid>
        {dataNumbers.map((data, key) => {
          return (
            <Grid item xs={12} md={3} key={key}>
              <Typography
                variant="h1"
                mt={{ md: 0, xs: 3 }}
                sx={{
                  fontWeight: 700,
                  fontSize: 76,
                  "@media (max-width: 400px)": {
                    fontSize: 72,
                  },
                }}
              >
                {data.title}
              </Typography>
              <Typography
                variant="h3"
                mt={{ md: 5, xs: 2 }}
                sx={{
                  fontWeight: 400,
                  fontSize: 20,
                  fontFamily: "Merriweather, serif",
                  lineHeight: "32px",
                }}
              >
                {data.text}
              </Typography>
            </Grid>
          );
        })}
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "left",
          }}
          mt={8}
        >
          <Typography className="source" variant="body2">
            {t("numbers.detail")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default Features;
