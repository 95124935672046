import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import "./EpisodeDetail.css";
import Header from "../../../components/commons/Header/Header";
import Footer from "../../../components/commons/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Grid, Container, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { doc, getDocs, collection, DocumentData } from "firebase/firestore";
import { db } from "../../../firebase";
import { getSpotifyToken, getEpisode } from "../../../service/spotifyApi";
import SubscribeSection from "../SubscribeSection/SubscribeSection";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LinkedinReels from "../Linkedin/LinkedinReels";

import moment from "moment";
import "moment/locale/es";

export default function EpisodeDetail() {
  const [t, i18n] = useTranslation("global");
  const [token, setToken] = useState("");
  const [episode, setEpisode] = useState<any>(null);
  const [reels, setReels] = useState<any>([]);
  let allReels: DocumentData[] = [];
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const episodeId = searchParams.get("ep");
  moment.locale("es");
  let duracion = moment.duration(episode?.duration_ms, "milliseconds");

  // Obtener los minutos y segundos
  let minutos = Math.floor(duracion.asMinutes());
  let segundos = Math.floor(duracion.asSeconds() % 60);
  const epTime = minutos + "min" + " " + segundos + "s";

  const description = episode?.html_description.split("---");
  const goBack = () => {
    navigate(`/taroko-talks`);
  };

  useEffect(() => {
    // This will scroll the window to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getSpotifyToken();
        setToken(accessToken);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    fetchToken();
  }, []);

  useEffect(() => {
    const fetchEpisode = async () => {
      if (token) {
        try {
          const episodeInfo = await getEpisode(episodeId, token);
          setEpisode(episodeInfo);
        } catch (error) {
          console.error("Error fetching episode details:", error);
        }
      }
    };

    fetchEpisode();
  }, [token, episodeId]);

  const srcEp = `https://open.spotify.com/embed/episode/${episodeId}?utm_source=oembed`;
  const episodeImg = episode?.images[0].url;

  useEffect(() => {
    const fetchAllReels = async () => {
      const querySnapshot = await getDocs(collection(db, "podcast-reel"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        allReels.push(doc.data());
        setReels(allReels);
      });
    };
    fetchAllReels();
  }, []);

  const findReel = reels.find(
    (item: any) => episode?.release_date == item.date
  );

  return (
    <div className="podcast-back">
      <Header type="w-list" />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          color: "#fff",
          backgroundColor: "var(--primary-700)",
        }}
      >
        <Grid item md={12} xs={12} py={{ md: 8, xs: 4 }} pl={{ md: 8, xs: 2 }}>
          <Button
            variant="text"
            onClick={goBack}
            sx={{
              paddingLeft: 0,
              marginBottom: 1,
            }}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: 20, color: "#fff" }} />
            <Typography pl={1} variant="body2" sx={{ color: "#fff" }}>
              {t("taroko-talks.back")}
            </Typography>
          </Button>
          <Typography mt={2} variant="h2">
            {episode?.name}
          </Typography>
          <Typography
            variant="body2"
            mt={2}
            sx={{
              color: "var(--primary-80)",
            }}
          >
            {moment(episode?.release_date).format(" DD MMMM YYYY")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        pt={{ md: 10, xs: 4 }}
        px={{ xl: 9, md: 8, xs: 2 }}
      >
        <Grid item md={7} xs={12} className="text-description">
          {episode && parse(description[0])}
        </Grid>
        <Grid item md={4} xs={12} mb={2} mt={2}>
          <img
            src={episodeImg}
            width="100%"
            style={{ borderRadius: "12px", marginBottom: 20 }}
          />
          <iframe
            style={{ borderRadius: "12px" }}
            src={srcEp}
            width="100%"
            height="152"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </Grid>
      </Grid>
      <SubscribeSection />
      <Grid
        container
        p={{ xl: 9, md: 8, xs: 2 }}
        sx={{
          backgroundColor: "var(--bg-beige)",
        }}
      >
        {findReel && <LinkedinReels episodeReel={findReel} />}
      </Grid>
      <Footer />
    </div>
  );
}
