import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#005A30",
    },
    secondary: {
      main: "#FAFAFA",
    },
  },
  typography: {
    fontFamily: ["Merriweather Sans", "sans-serif"].join(","),
    fontSize: 16,
    fontWeightRegular: 400,
  },
});

theme.typography.h1 = {
  fontSize: "56px",
  fontWeight: "900",
  fontFamily: "Merriweather,serif",
  "@media (max-width: 400px)": {
    fontSize: 33,
  },
};
theme.typography.h2 = {
  fontSize: "34px",
  fontWeight: "700",
  fontFamily: "Merriweather,serif",
  "@media (max-width: 450px)": {
    fontSize: 30,
  },
};
theme.typography.h3 = {
  fontSize: "20px",
  fontFamily: "Merriweather Sans, sans-serif",
  "@media (max-width: 400px)": {
    fontSize: 18,
  },
};
theme.typography.subtitle2 = {
  fontSize: "30px",
  fontWeight: "700",
  fontFamily: "Merriweather , serif",
  "@media (max-width: 400px)": {
    fontSize: 18,
  },
};
theme.typography.body1 = {
  lineHeight: "24px",
  fontSize: "16px",
  fontWeight: 300,
  fontFamily: "Merriweather Sans, sans-serif",
};
theme.typography.body2 = {
  lineHeight: "24px",
  fontSize: "16px",
  fontWeight: 400,
  fontFamily: "Merriweather Sans, sans-serif",
};
theme.typography.button = {
  fontSize: "16px",
  fontWeight: "600",
  fontFamily: "Merriweather Sans, sans-serif",
};
