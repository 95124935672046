import React, { useEffect } from "react";
import Header from "../../components/commons/Header/Header";
import Footer from "../../components/commons/Footer/Footer";
import { Link } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function PrivacyPolicy() {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="contact-form">
      <Header />
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        mt={20}
        p={{ xl: 12 }}
        mb={10}
        px={{ xl: 15, xs: 2 }}
      >
        <Grid item sm={8}>
          <Typography variant="h2" mb={4}>
            {t("privacy-policy.main-title")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.paragraph1")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.aim")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.aim-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.responsibility")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.responsibility-text")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.responsibility-text2")}{" "}
            <Link to="mailto:support@taroko.la">
              {t("privacy-policy.email")}
            </Link>
          </Typography>

          <Typography variant="h3" mb={2}>
            {t("privacy-policy.personal-data")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.personal-data-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.data-collection")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.data-collection-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.data-treatment")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.data-treatment-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.purpose-treatment")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.purpose-treatment-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.auth")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.auth-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.data-protection")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.data-protection-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.data-confidenciality")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.data-confidenciality-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.data-retention")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.data-retention-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.data-safeguard")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.data-safeguard-text")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.data-rights")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.data-rights-text")}{" "}
            <Link to="mailto:support@taroko.la">
              {t("privacy-policy.email")}
            </Link>
            {t("privacy-policy.data-rights-text2")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.cookies-use")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.cookies-use-text")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.cookies-use-text2")}
          </Typography>
          <Typography variant="h3" mb={2}>
            {t("privacy-policy.validity-currency")}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t("privacy-policy.validity-currency-text")}
          </Typography>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
