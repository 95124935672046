export const dataTabsEs = [
  {
    id: "1",
    title:
      "Accede a una cartera preseleccionada de inversiones del mercado privado",
    text: "Brindamos productos institucionales exclusivos de fondos de inversión, en alianza con firmas de inversión y Family Offices Globales de primer nivel.",
  },
  // {
  //   id: "2",
  //   title: "Diversifique y potencie su cartera de inversión",
  //   text: "Construye estrategias diversificadas de inversiones privadas en múltiples activos que brinden mayores rendimientos y menor volatilidad que las carteras tradicionales.",
  // },
  {
    id: "2",
    title: "Alcanza tu máximo potencial con nuestro canal premium educacional",
    text: "Simplificamos el mundo de las inversiones en el mercado privado al brindar contenido educativo de alta calidad a través de nuestra plataforma integrada y canal de podcast.",
  },
];

export const dataTabsEn = [
  {
    id: "1",
    title: "Access a curated portfolio of alternative investments",
    text: "We bring an exclusive portfolio of alternative investment funds by partnering with top-tier Global Family Offices and Asset Manager.",
  },
  // {
  //   id: "2",
  //   title: "Diversify and enhance your investment portfolio",
  //   text: "Build a strategy of diversified multi-asset private investments that offer higher returns and less volatility than traditional portfolios.",
  // },
  {
    id: "2",
    title: "Achieve full potential with our education & media channel",
    text: "We simplify alternative investments by offering high-quality educational materials through our integrated platform and podcast channel.",
  },
];
