import React, { useEffect, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import {
  AppBar,
  Toolbar,
  Typography,
  useScrollTrigger,
  Slide,
  Grid,
  Button,
  IconButton,
  Box,
  Divider,
  ListItemButton,
  List,
  ListItem,
  ListItemText,
  Drawer,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  windows?: () => Window;
  children?: any;
  type?: string;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function HideAppBar(props: Props) {
  const [t, i18n] = useTranslation("global");
  const lng = i18n.resolvedLanguage;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const env = process.env.REACT_APP_SPOTIFY_ENV;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  const listenScrollEvent = (e: any) => setIsScrolled(window.scrollY > 100);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, []);
  const trackClickButton = async () => {
    amplitude.track("Button to waiting list");
  };

  const { windows } = props;

  const showButton =
    props.type !== "w-list" ? (
      <Button
        onClick={trackClickButton}
        variant="contained"
        color="primary"
        sx={{ padding: "10px 18px", borderRadius: 3, textAlign: "rigth" }}
      >
        {t("banner.button")}
      </Button>
    ) : null;

  const drawerWidth = 300;

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        textDecoration: "none",
      }}
    >
      <Typography
        variant="h2"
        component="div"
        pl={{ md: 5, xs: 0 }}
        py={{ xs: 2 }}
        sx={{
          color: "var(--text-primary)",
          "@media (max-width: 400px)": {},
        }}
      >
        Taroko
      </Typography>
      <Divider />
      <List className="drawer">
        <HashLink to={"/"} className="nav-link">
          <ListItem>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText>{t("header.home")}</ListItemText>
            </ListItemButton>
          </ListItem>
        </HashLink>
        <HashLink to={"/our-platform"} className="nav-link">
          <ListItem>
            <ListItemButton sx={{ textAlign: "center", fontWeight: 700 }}>
              <ListItemText>{t("header.how-works")}</ListItemText>
            </ListItemButton>
          </ListItem>
        </HashLink>
        <HashLink to={"/taroko-talks"} className="nav-link">
          <ListItem>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText>{t("header.taroko-talks")}</ListItemText>
            </ListItemButton>
          </ListItem>
        </HashLink>
      </List>
      <Grid mt={2}>
        <Link
          to="https://www.linkedin.com/company/taroko-investments"
          target="_blank"
          className="nav-link"
        >
          <img src={`${env}/img/icons/linkedin-icon.svg`} alt="linkedin" />
        </Link>
      </Grid>
    </Box>
  );

  const container =
    windows !== undefined ? () => windows().document.body : undefined;

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar
          elevation={0}
          className="header"
          sx={{ bgcolor: "var(--white)" }}
        >
          <Toolbar>
            <Grid container spacing={2} pt={{ xs: 1 }}>
              <Grid item xs={2} sx={{ display: { sm: "none" } }}>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6} md={2}>
                <Link to={"/"} className="nav-link">
                  <Typography
                    variant="h2"
                    component="div"
                    pl={{ md: 5, xs: 0 }}
                    sx={{
                      color: "var(--text-primary)",
                      "@media (max-width: 400px)": {},
                    }}
                  >
                    Taroko
                  </Typography>
                </Link>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                xs={7}
                sx={{
                  display: { xs: "none", md: "inherit" },
                }}
              >
                <HashLink smooth to={"/"} className="nav-link">
                  <Button
                    sx={{
                      color: "var(--text-primary)",
                      fontWeight: 400,
                      marginLeft: 5,
                    }}
                  >
                    {t("header.home")}
                  </Button>
                </HashLink>
                <HashLink smooth to={"/our-platform"} className="nav-link">
                  <Button
                    sx={{
                      color: "var(--text-primary)",
                      fontWeight: 400,
                      marginLeft: 5,
                    }}
                  >
                    {t("header.how-works")}
                  </Button>
                </HashLink>
                <HashLink smooth to={"/taroko-talks"} className="nav-link">
                  <Button
                    sx={{
                      color: "var(--text-primary)",
                      fontWeight: 400,
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    {t("header.taroko-talks")}
                  </Button>
                </HashLink>
              </Grid>
              <Grid item md={1} xs={4}>
                <Grid container>
                  <IconButton onClick={() => i18n.changeLanguage("es")}>
                    <Typography
                      variant="body2"
                      className="ln-buttom"
                      sx={{ fontWeight: lng === "es" ? 700 : 300 }}
                    >
                      ES
                    </Typography>
                  </IconButton>
                  <Grid>
                    <Typography
                      variant="body2"
                      className="ln-buttom"
                      pt={1}
                      px={1}
                    >
                      |
                    </Typography>
                  </Grid>
                  <IconButton onClick={() => i18n.changeLanguage("en")}>
                    <Typography
                      variant="body2"
                      className="ln-buttom"
                      sx={{ fontWeight: lng === "en" ? 700 : 300 }}
                    >
                      EN
                    </Typography>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                xs={2}
                justifyContent="flex-end"
                pr={5}
                sx={{
                  display: {
                    xs: "none",
                    md: props.type !== "w-list" ? "inherit" : "none",
                  },
                }}
              >
                <Link to="/waiting-list" className="nav-link">
                  {showButton}
                </Link>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "var(--bg-beige)",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </React.Fragment>
  );
}
