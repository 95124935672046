import React from "react";
import "./Podcast.css";
import Footer from "../../components/commons/Footer/Footer";
import Header from "../../components/commons/Header/Header";
import Banner from "./Banner/Banner";
import TarokoTalks from "./TarokoTalks/TarokoTalks";
import Platform from "./Platform/Platform";
import SubscribeSection from "../TarokoTalks/SubscribeSection/SubscribeSection";

function Podcast() {
  return (
    <div>
      <Header type="w-list" />
      <Banner />
      <Platform />
      <TarokoTalks />
      <SubscribeSection />
      <Footer type="landing" />
    </div>
  );
}

export default Podcast;
