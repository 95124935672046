import axios from "axios";

const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID; // Reemplaza con tu Client ID
const clientSecret = process.env.REACT_APP_SPOTIFY_SECRET; // Reemplaza con tu Client Secret

const getSpotifyToken = async () => {
  const authParams = new URLSearchParams();
  authParams.append("grant_type", "client_credentials");

  try {
    const response = await axios.post(
      "https://accounts.spotify.com/api/token",
      authParams,
      {
        headers: {
          Authorization: "Basic " + btoa(clientId + ":" + clientSecret),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data.access_token;
  } catch (error) {
    console.error("Error obtaining Spotify token:", error);
    throw error;
  }
};

const getEpisode = async (episodeId, token) => {
  try {
    const response = await axios.get(
      `https://api.spotify.com/v1/episodes/${episodeId}?market=ES`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching episode data:", error);
    throw error;
  }
};

const getPodcastEpisodes = async (podcastId, token, limit, offset) => {
  try {
    const response = await axios.get(
      `https://api.spotify.com/v1/shows/${podcastId}/episodes`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit,
          offset,
        },
      }
    );

    const data = {
      totalEpisodes: response.data.total,
      episodes: response.data.items,
    };
    return data; // This returns the list of episodes
  } catch (error) {
    console.error("Error fetching podcast episodes:", error);
    throw error;
  }
};

const getLastFiveEpisodes = async (podcastId, token) => {
  try {
    const response = await axios.get(
      `https://api.spotify.com/v1/shows/${podcastId}/episodes`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit: 5,
        },
      }
    );
    return response.data.items; // This returns the list of episodes
  } catch (error) {
    console.error("Error fetching podcast episodes:", error);
    throw error;
  }
};

const getPodcastDetails = async (podcastId, token) => {
  try {
    const response = await axios.get(
      `https://api.spotify.com/v1/shows/${podcastId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // This returns details of the podcast, including description
  } catch (error) {
    console.error("Error fetching podcast details:", error);
    throw error;
  }
};

export {
  getSpotifyToken,
  getEpisode,
  getPodcastEpisodes,
  getPodcastDetails,
  getLastFiveEpisodes,
};
