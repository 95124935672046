import * as React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "./FlipCard.css";

interface Props {
  img: string;
  title: string;
  description: string;
  icon: string;
}

export default function FlipCard({ img, icon, title, description }: Props) {
  const url = `./img/${img}`;
  const iconUrl = `./img/icons/${icon}`;
  return (
    <div className="card-container">
      <div className="left"></div>
      <div className="right"></div>
      <Grid className="card">
        <Grid container className="front">
          <Grid className="cover">
            <img src={url} className="img-card" />
          </Grid>
          <Grid pl={2} className="title-container">
            <Typography className="title">{title}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          className="back"
        >
          <Grid mt={2} ml={2}>
            <img src={iconUrl} alt="Tierras" />
          </Grid>
          <Grid mx={2} className="text-back">
            <Typography className="title-back">{title}</Typography>
            <Typography variant="body2" className="description">
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
