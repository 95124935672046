import React from "react";
import Carousel from "react-material-ui-carousel";
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { dataTabsEs, dataTabsEn } from "../VerticalTabs/tabsConstants";
import "../VerticalTabs/VerticalTabs.css";

export default function CarouselFeature() {
  const [t, i18n] = useTranslation("global");
  const lng = i18n.resolvedLanguage;
  const dataProds = lng === "es" ? dataTabsEs : dataTabsEn;
  const TabBox = ({ data }: any) => {
    const info = data;
    return (
      <Box
        sx={{
          color: "var(--text-primary)",
          textAlign: "left",
          height: "100%",
        }}
        py={{ xs: 2 }}
        px={{ xs: 3 }}
      >
        <Typography variant="h3">{info.title}</Typography>
        <Typography pt={{ md: 2, xs: 1 }}>{info.text}</Typography>
        <img
          src="./img/icons/arrow-right.svg"
          alt="arrow"
          className="arrow-icon"
        />
      </Box>
    );
  };
  const optionsButton = [
    <TabBox data={dataProds[0]} />,
    <TabBox data={dataProds[1]} />,
    // <TabBox data={dataProds[2]} />,
  ];
  const imgIphone =
    lng === "es" ? "./img/img-mainpage.png" : "./img/en/img-mainpage.png";
  // const imgOptions =
  //   lng === "es" ? "./img/options.png" : "./img/en/options.png";
  const imgDash =
    lng === "es" ? "./img/education-img.png" : "./img/en/education-img.png";

  return (
    <Carousel
      autoPlay={true}
      navButtonsAlwaysInvisible
      height="590px"
      IndicatorIcon={optionsButton}
      sx={{
        justifyContent: "flex-end",
      }}
      indicatorIconButtonProps={{
        style: {
          color: "blue",
          borderRadius: "0px",
          borderLeft: "solid 4px var(--line-grey)",
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          borderLeft: "solid 4px var(--primary)", // 2
          borderRadius: "0px",
        },
      }}
      indicatorContainerProps={{
        style: {
          width: "40%",
          position: "absolute",
          zIndex: 100,
          top: 0,
        },
      }}
    >
      <Grid container justifyContent="flex-end">
        <Grid item md={7}>
          <img
            src={imgIphone}
            className="tab-img fade-in-image move-in"
            id="iphone"
          />
        </Grid>
      </Grid>
      {/* <Grid container justifyContent="flex-end">
        <Grid item md={7}>
          <img
            src={imgOptions}
            className="tab-img fade-in-image move-in"
            id="options"
          />
        </Grid>
      </Grid> */}
      <Grid container justifyContent="flex-end">
        <Grid item md={7}>
          <img
            src={imgDash}
            className="tab-img fade-in-image move-in"
            id="dash"
          />
        </Grid>
      </Grid>
    </Carousel>
  );
}
