import React from "react";
import ReactDOM from "react-dom/client";
import * as amplitude from "@amplitude/analytics-browser";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./global.css";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "./i18n";

amplitude.init("fa635bb352a6f183bfbaad8d478c9f80", undefined, {
  logLevel: amplitude.Types.LogLevel.Error,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
