import React from "react";
import Carousel from "react-material-ui-carousel";
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { dataTabsEs, dataTabsEn } from "../VerticalTabs/tabsConstants";
import "../VerticalTabs/VerticalTabs.css";

export default function CarouselMobile() {
  const [t, i18n] = useTranslation("global");
  const lng = i18n.resolvedLanguage;
  const dataProds = lng === "es" ? dataTabsEs : dataTabsEn;
  const TabBox = ({ data }: any) => {
    const info = data;
    return (
      <Box
        sx={{
          color: "var(--text-primary)",
          textAlign: "left",
          height: "100%",
          borderLeft: "solid 3px var(--primary)",
        }}
        py={{ xs: 2 }}
        px={{ xs: 3 }}
      >
        <Typography variant="h3">{info.title}</Typography>
        <Typography pt={{ md: 2, xs: 1 }}>{info.text}</Typography>
        <img
          src="./img/icons/arrow-right.svg"
          alt="arrow"
          className="arrow-icon"
        />
      </Box>
    );
  };

  const imgIphone =
    lng === "es" ? "./img/img-mainpage.png" : "./img/en/img-mainpage.png";
  // const imgOptions =
  //   lng === "es" ? "./img/options.png" : "./img/en/options.png";
  const imgDash =
    lng === "es" ? "./img/education-img.png" : "./img/en/education-img.png";

  return (
    <Carousel
      interval={8000}
      indicators={true}
      autoPlay={true}
      animation="slide"
      navButtonsAlwaysInvisible
      height="600px"
    >
      <Grid container justifyContent="center">
        <TabBox data={dataProds[0]} />
        <Grid item xs={6}>
          <img src={imgIphone} className="tab-img fade-in-image" id="iphone" />
        </Grid>
      </Grid>
      {/* <Grid container justifyContent="flex-end">
        <TabBox data={dataProds[1]} />
        <Grid item xs={12}>
          <img
            src={imgOptions}
            className="tab-img fade-in-image"
            id="options"
          />
        </Grid>
      </Grid> */}
      <Grid container justifyContent="flex-end">
        <TabBox data={dataProds[1]} />
        <Grid item xs={12} sx={{ overflow: "hidden" }}>
          <img src={imgDash} className="tab-img fade-in-image" id="dash" />
        </Grid>
      </Grid>
    </Carousel>
  );
}
