export const featureDataEs = [
  {
    title: "+99%",
    text: " de las personas no tiene acceso a este tipo de productos",
  },
  {
    title: "20",
    text: " años de rendimiento superior de las carteras que incorporaron inversiones alternativas*",
  },
  {
    title: "45%",
    text: "del patrimonio de las grandes fortunas está en inversiones institucionales*",
  },
];

export const featureDataEn = [
  {
    title: "99%+",
    text: " of the people don’t have access to these type of investment products",
  },
  {
    title: "20",
    text: " years of outperformance of portfolios that included alternative investments*",
  },
  {
    title: "45%",
    text: "of the allocation of the ultra-wealthy goes into alternative investments*",
  },
];
